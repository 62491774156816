import { readonly, ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { type Member } from '@/js/lib/_env';
import { credentials } from '@/js/lib/api/user';
import { useUserCredentialStore } from './user-credential';
import { useUserPermissionStore } from './user-permission';
import { getFromStorage, setToStorage } from '@/js/lib/helper/expirable-local-storage';

const setSignined = () => {
  setToStorage({ key: 'signined', value: true, expirationSeconds: 86400 * 30 });
};

export const useUserStore = defineStore('user', () => {
  const { hasCredential, updateCredential, resetCredential } = useUserCredentialStore();
  const { hasPermission, updatePermission } = useUserPermissionStore();
  const memberId = ref<number>();
  const aliasOrSlug = ref('');
  const memberName = ref('');
  const email = ref('');
  const picturePath = ref('');
  const prefecture = ref('');
  const isAgentLogin = ref(false);
  const isAuthenticated = ref(false);
  const isBlackListed = ref(false);
  const isEmailConfirmed = computed(() => hasCredential(credentials.emailConfirmed));
  const hasSignined = computed(() => Boolean(getFromStorage({ key: 'signined' })));
  function assignMember(member: Member) {
    memberId.value = member.member_id;
    aliasOrSlug.value = member.alias || member.slug;
    memberName.value = member.member_name;
    email.value = member.email;
    picturePath.value = member.picture_path;
    prefecture.value = member.prefecture;
    isAgentLogin.value = member.a;
    isBlackListed.value = member.b;
    isAuthenticated.value = Boolean(member.member_id);
  }

  return {
    memberId: readonly(memberId),
    aliasOrSlug: readonly(aliasOrSlug),
    memberName: readonly(memberName),
    email: readonly(email),
    picturePath: readonly(picturePath),
    prefecture: readonly(prefecture),
    isAgentLogin: readonly(isAgentLogin),
    isBlackListed: readonly(isBlackListed),
    isAuthenticated: readonly(isAuthenticated),
    hasSignined,
    isEmailConfirmed,
    hasCredential,
    hasPermission,
    assignMember,
    setSignined,
    updateCredential,
    resetCredential,
    updatePermission,
  };
});
