const escape = (str: string) => str.replaceAll('&', '&amp;').replaceAll('"', '&quot;').replaceAll('<', '&lt;').replaceAll('>', '&gt;').replaceAll("'", '&#39;');

export const linkify = (str: string) =>
  str.replaceAll(/(https:\/\/[^\s&/:<>]+(?::\d+)?(?:\/[^\s"#&'()<>]*(?:#[^\s"&'()<>]+)?)?)|(.)/gi, (_all, url, normal) => {
    if (url) {
      if (/^https:\/\/(www.)?iichi.com/.test(url)) {
        const safeUrl = escape(url);
        return `<a href="${safeUrl}">${safeUrl}</a>`;
      }
      return url;
    }
    return normal;
  });
