import axios from '@/js/lib/axios';
import { updateToken } from './update_token';
import { uniq } from '@/js/lib/helper/array';
import { isEmpty } from '@/js/lib/helper/is-empty';
import { toCamelCaseKeys } from '@/js/lib/helper/localize-keys';

export interface Item {
  itemId: number;
  title: string;
  price: number;
  imagePath: string;
  published?: boolean;
  stock?: number;
  shopId?: number;
  memberName?: string;
  aliasOrSlug?: string;
}

export interface ItemOption {
  itemOptionSlug: string;
  name: string;
  required: 0 | 1;
  itemOptionVariants: ItemOptionVariant[];
}

interface ItemOptionVariant {
  itemOptionVariantSlug: string;
  name: string;
  price: number;
}

export interface RecentlySoldItem {
  imagePath: string;
  itemId: number;
  price: number;
  title: string;
}

export interface SameCategoryItem {
  firstListedAt: number;
  imagePath: string;
  itemId: number;
  lastListedAt: number;
  price: number;
  title: string;
}

interface ItemLiking {
  itemId: string;
  liked: 0 | 1;
}

export const getItems = async (itemIds: number[] = [], fields = ['item_id', 'title', 'price', 'image_path', 'published', 'stock']): Promise<Item[]> => {
  const res = await axios.get('/api/item', {
    params: {
      item_ids: itemIds.join(','),
      fields: fields.join(','),
    },
  });
  return toCamelCaseKeys(res.data);
};

export const getItemOptionsOfItem = async (itemId: number): Promise<{ itemOptions: ItemOption[] }> => {
  const res = await axios.get(`/api/item/${itemId}/item-options`);
  return toCamelCaseKeys(res.data);
};

export const countItemLikes = async ({ itemIds }: { itemIds: number[] }): Promise<Record<number, number>> => {
  const res = await axios.get<{ item_id: number; count: number }[]>('/api/like/count/item-likes', {
    params: {
      item_ids: itemIds.join(','),
    },
  });
  const result = {};
  if (Array.isArray(res.data)) {
    res.data.forEach((item) => {
      result[item.item_id] = item.count;
    });
  }
  return result;
};

export const isItemLiking = async ({ itemIds }: { itemIds: number[] }): Promise<ItemLiking[]> => {
  const res = await axios.get('/api/is-liked/items', {
    params: {
      item_ids: itemIds.join(','),
    },
  });
  return toCamelCaseKeys(res.data);
};

export const likeItem = async ({ itemId }: { itemId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('item_id', String(itemId));
  params.append('token', token);
  const res = await axios.post('/api/like/item', params);
  return res.data;
};

export const unlikeItem = async ({ itemId }: { itemId: number }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('item_id', String(itemId));
  params.append('token', token);
  const res = await axios.post('/api/like/unlink/item', params);
  return res.data;
};

export const requestStart = async ({ itemId, email, followMember, mailMagazine }): Promise<''> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('item_id', itemId);
  params.append('token', token);
  params.append('address', email);
  if (followMember) {
    params.append('follow_member', followMember);
  }
  if (mailMagazine) {
    params.append('other_notifications', mailMagazine);
  }
  const res = await axios.post('/api/request/start', params);
  return res.data;
};

export const getRecentlySoldItems = async ({ categoryId, limit = 10 }: { categoryId?: number; limit?: number }): Promise<RecentlySoldItem[]> => {
  const res = await axios.get('/api/item/recently-sold', {
    params: {
      category_id: categoryId,
      limit,
    },
  });
  return toCamelCaseKeys(res.data);
};

export const getSameCategoryItems = async ({ categoryId, price, isVintage }: { categoryId: number; price: number; isVintage: boolean }): Promise<SameCategoryItem[]> => {
  const res = await axios.get('/api/item/same-category', {
    params: {
      category_id: categoryId,
      price,
      vintage: isVintage ? 1 : 0,
    },
  });
  const items = res.data.filter((item) => !isEmpty(item));
  return toCamelCaseKeys(items);
};

export const getHistoryItems = async ({ limit = 80 } = {}): Promise<Item[]> => {
  if (!localStorage) {
    console.warn('localStorage is not defined.');
    return [];
  }
  const itemIds = JSON.parse(localStorage.getItem('item-history') || '[]');
  if (itemIds.length === 0) {
    return [];
  } else {
    const items = await getItems(itemIds.slice(0, limit));
    return items.filter((item) => !isEmpty(item) && Boolean(item.published));
  }
};

export const addHistoryItem = async ({ itemId }: { itemId: number }): Promise<void> => {
  const itemIds = JSON.parse(localStorage.getItem('item-history') || '[]');
  itemIds.unshift(itemId);
  if (!localStorage) {
    console.warn('localStorage is not defined.');
    return;
  }
  localStorage.setItem('item-history', JSON.stringify(uniq(itemIds).slice(0, 80)));
};
