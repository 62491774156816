import axios from '@/js/lib/axios';
import { updateToken } from './update_token';
import { toCamelCaseKeys } from '@/js/lib/helper/localize-keys';

interface CartItem {
  itemId: number;
  quantity: number;
}

export interface CartBasket {
  shopId: number;
  items: CartItem[];
}

export const addToBasket = async ({ itemId, itemOptionVariantSlugs }: { itemId: number; itemOptionVariantSlugs: string[] }): Promise<''> => {
  const token = await updateToken();

  const res = await axios.post('/api/cart/add', {
    item_id: itemId,
    quantity: 1,
    item_option_variant_slugs: itemOptionVariantSlugs,
    token,
  });

  return res.data;
};

export const removeFromBasket = async ({ itemId }: { itemId: number }): Promise<{ error?: '1' }> => {
  const token = await updateToken();
  const params = new URLSearchParams();
  params.append('item_id', String(itemId));
  params.append('token', token);
  const res = await axios.post('/api/cart/remove_item', params);
  return res.data;
};

export const updateQuantity = async ({ shopId, itemId, quantity }: { shopId: number; itemId: number; quantity: number }): Promise<''> => {
  const token = await updateToken();
  const res = await axios.post('/api/cart/update-quantity', {
    shop_id: shopId,
    item_id: itemId,
    quantity,
    token,
  });
  return res.data;
};

export const getBaskets = async (): Promise<CartBasket[]> => {
  const res = await axios.get('/api/cart/baskets');
  return toCamelCaseKeys(res.data);
};
