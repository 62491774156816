// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ItemOptions__optionList--S5ji3{padding-bottom:6px}.ItemOptions__optionItem--QIfcc{margin-bottom:16px}.ItemOptions__optionTitle--btcDL{font-size:14px;margin-bottom:8px}.ItemOptions__optionTitle--btcDL .ItemOptions__required--cStJm{background-color:#d9d9d9;border-radius:3px;font-size:12px;margin-left:8px;padding:3px 5px}.ItemOptions__optionSelect--lMh8p .el-input__inner{border-radius:4px;font-size:14px}", "",{"version":3,"sources":["webpack://./assets/src/js/components/listing/ItemOptions.vue"],"names":[],"mappings":"AAEA,gCACE,kBAAA,CAGF,gCACE,kBAAA,CAGF,iCACE,cAAA,CACA,iBAAA,CAEA,+DACE,wBAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CAIJ,mDACE,iBAAA,CACA,cAAA","sourcesContent":["@import '@/scss/base/variables';\n\n.optionList {\n  padding-bottom: 6px;\n}\n\n.optionItem {\n  margin-bottom: 16px;\n}\n\n.optionTitle {\n  font-size: 14px;\n  margin-bottom: 8px;\n\n  .required {\n    background-color: #d9d9d9;\n    border-radius: 3px;\n    font-size: 12px;\n    margin-left: 8px;\n    padding: 3px 5px;\n  }\n}\n\n.optionSelect :global(.el-input__inner) {\n  border-radius: 4px;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionList": "ItemOptions__optionList--S5ji3",
	"optionItem": "ItemOptions__optionItem--QIfcc",
	"optionTitle": "ItemOptions__optionTitle--btcDL",
	"required": "ItemOptions__required--cStJm",
	"optionSelect": "ItemOptions__optionSelect--lMh8p"
};
module.exports = ___CSS_LOADER_EXPORT___;
